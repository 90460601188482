<div class="grid grid-nogutter mb-3"  *ngIf="!isReviewer">
    <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
        <h1 class="h4 m-0">{{title}}</h1>
        @if(isbreadcrumb){
            <div class="breadcrumb">
                <p-breadcrumb 
                    class="max-w-full" 
                    [model]="items" />
            </div>
        }
    </div>
    <div class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center  md:gap-4 gap-0 ">
        @if(showToggle){
            <div class="switch-w-label pt-2  flex gap-2">
                <p-inputSwitch [(ngModel)]="showCurrentUserProjects" (ngModelChange)="onMyProjectToggleSwitch()" class="mpl-switch" inputId="stfProject" ariaLabel="My Projects" />
                <label for="stfProject" class="line-height-2">My Projects</label>
            </div>
        }
        @if(button){
            <p-button [style]="{ background: button.color ? button.color: ''}"  [routerLink]="button.url" [label]="button.label" [rounded]="true" [icon]="button.icon" size="large"/>
        }
        @if(extraButton){
            <p-button [routerLink]="extraButton.url" [label]="extraButton.label" [rounded]="true" [icon]="button.icon" size="large"/>
        }
    </div>
</div>


